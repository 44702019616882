$(document).ready(function() {
	$('.tooltip').tooltipster({maxWidth: '200', position: 'bottom-left'});

	$('tr td a.buy_enquire_link, tr td a.company_link').click(function() {
		product_name = $(this).parent().parent().data('product');
		company_name = $(this).parent().parent().data('company');
		ga('send', {
		'hitType': 'event',
		'eventCategory': 'Product Link Click',
		'eventAction': company_name,
		'eventLabel':  product_name,
		'eventValue': 1
		});
	});

	$('button.enquiry_form_submit').click(function() {
		product_name = $('button.enquiry_form_submit').parents('.modal-content').find('#enquiry_product_name').val()
		company_name = $('button.enquiry_form_submit').parents('.modal-content').find('#enquiry_product_company_name').val()
		ga('send', {
		'hitType': 'event',
		'eventCategory': 'Product Enquiry Made',
		'eventAction': company_name,
		'eventLabel':  product_name,
		'eventValue': 1
		});
	});

	$('a.email_enquiry').click(function() {
		product_name = $(this).parent().parent().data('product');
		company_name = $(this).parent().parent().data('company');
		ga('send', {
		'hitType': 'event',
		'eventCategory': 'Product Enquiry Clicked',
		'eventAction': company_name,
		'eventLabel':  product_name,
		'eventValue': 1
		});
		// console.log(product_name);
		// console.log(company_name);
		// return false;
	});

	$('a.view_all_products').click(function() {
		product_name = $(this).parent().parent().data('product');
		company_name = $(this).parent().parent().data('company');
		ga('send', {
		'hitType': 'event',
		'eventCategory': 'View Suppliers Products Clicked',
		'eventAction': company_name,
		'eventLabel':  product_name,
		'eventValue': 1
		});
	});
});



$(document).ready(function(){
	$('#enquiry_modal').on('show.bs.modal', function (event) {

	var button = $(event.relatedTarget) // Button that triggered the modal
	var product_id = button.data('product') // Extract info from data-* attributes
	var company = button.data('company') // Extract info from data-* attributes
	var modal = $(this)

		$.get( "/products/enquiry_form/"+product_id, function( data ) {
			modal.find('.modal-title').text('New enquiry to ' + company)
			modal.find('.modal-data').html( data );
		});
	})	

	$('.enquiry_form_submit').on('click', function(){
		$('.enquiry_form').submit();
	});
});